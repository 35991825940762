import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import HttpClient from './network/http';
import Installation from './service/installation';
import axios from 'axios';
import "./utils/i18n"

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL);
const installation = new Installation(httpClient);
axios.defaults.baseURL = 'https://'+baseURL;

ReactDOM.render(
    <BrowserRouter>
        <App installation={installation} />
    </BrowserRouter>,
  document.getElementById('root')
);
