import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

function CSIS() {

    const { t } = useTranslation();

    return (
        <section className={styles.main_container}>
            <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                {t("충전소 보안 시스템 (CSSS)")}
            </h3>

            <div className={`${styles.csms} ${styles.csms_2} ${styles.add_platform_5}`} data-aos="fade-right">
                <div className={`${styles.csms_txt} ${styles.csms_txt_2} ${styles.add_platform_5_txt}`}>
                    <div className={styles.csms_title} data-aos="fade-left">
                        {t("AI 충전소 보안 시스템")}
                    </div>

                    <ul data-aos="fade-left">
                        <li>
                            <span className={styles.txt_span}>▪</span> {t("AI 객체인식 기반 차량 번호 인식")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("자동 결제")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("예약 확인")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("사용자 확인/안내")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("AI 지능형 CCTV")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("이벤트 녹화/전송")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("침입/이상 행동 감지")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("KISA 인증 Face detection(K-NBTC 인증)")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("개인정보(얼굴, 차량 번호 등) 마스킹")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("원격 관제 서비스")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("화재감시지원 시스템 연동")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("이벤트 기반 지능형 관제시스템 제공")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("웹/모바일 알림 서비스")}<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {t("엣지 상태 모니터링 및 제어")}
                        </li>
                    </ul>
                </div>
            </div>





            <div className={`${styles.csms} ${styles.add_platform_6}`} data-aos="fade-right">
                <div className={`${styles.csms_txt} ${styles.add_platform_6_txt}`}>
                    <div className={styles.csms_title} data-aos="fade-left">
                        {t("AI 충전소 보안 시스템")}
                    </div>

                    <ul data-aos="fade-left">
                        <li>
                            <span className={styles.txt_span}>▪</span> {t("AI 객체인식 기반 차량 번호 인식")}<br/>
                            - {t("자동 결제")}<br/>
                            - {t("예약 확인")}<br/>
                            - {t("사용자 확인/안내")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("AI 지능형 CCTV")}<br/>
                            - {t("이벤트 녹화/전송")}<br/>
                            - {t("침입/이상 행동 감지")}<br/>
                            - {t("KISA 인증 Face detection(K-NBTC 인증)")}<br/>
                            - {t("개인정보(얼굴, 차량 번호 등) 마스킹")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("원격 관제 서비스")}<br/>
                            - {t("화재감시지원 시스템 연동")}<br/>
                            - {t("이벤트 기반 지능형 관제시스템 제공")}<br/>
                            - {t("웹/모바일 알림 서비스")}<br/>
                            - {t("엣지 상태 모니터링 및 제어")}
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default CSIS
