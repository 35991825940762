import styles from '../../css/style.module.css';
import { Trans, useTranslation } from "react-i18next";

function CSMS() {

    const { t } = useTranslation();

    return (
        <section className={styles.main_container}>
            <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                {t("충전소 관리 시스템 (CSMS)")}
            </h3>

            <div className={`${styles.csms} ${styles.csms_1}`} data-aos="fade-left">
                <div className={`${styles.csms_txt} ${styles.csms_txt_1}`}>
                    <div className={styles.csms_title} data-aos="fade-right">
                        {t("충전 사업 운영자 관리 시스템")}
                    </div>

                    <ul data-aos="fade-right">
                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 등록 관리 – 주소, 운영자, 이용시간, 요금")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 별 충전기 현황 및 관리")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("서비스 버전 확인, 원격 업데이트")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("장애 관리, 원격 점검")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("관리자 웹/모바일 알림/푸시 서비스")}
                        </li>
                    </ul>
                </div>
            </div>





            <div className={`${styles.csms} ${styles.csms_2}`} data-aos="fade-right">
                <div className={`${styles.csms_txt} ${styles.csms_txt_2}`}>
                    <div className={styles.csms_title} data-aos="fade-left">
                        {t("사용자 관리시스템 (web/mobile App.)")}
                    </div>

                    <ul data-aos="fade-left">
                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 검색 및 길 찾기 기능")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 이용 현황, 충전 가능 상태")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("차량 등록 시스템 및 자동 충전 개시")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전/완충 안내 및 중단 시 사용자 확인 서비스")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전기 예약 서비스 및 푸시 알림")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("월별 충전 요금, 전력 사용량 확인")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("결제 내역 및 포인트 충전, 사용 내역 확인")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("NFC 후불교통카드/삼성페이 결제 지원")}
                        </li>
                    </ul>
                </div>
            </div>





            <div className={`${styles.add_platform_1} ${styles.csms}`}data-aos="fade-left">
                <div className={`${styles.csms_txt} ${styles.add_platform_1_txt}`}>
                    <div className={styles.csms_title} data-aos="fade-right">
                        <Trans t={t} i18nKey={"CSMS:<0/>운영 시스템"} components={{ 1: <br/> }}>
                            CSMS:<br/>운영 시스템
                        </Trans>
                    </div>

                    <ul data-aos="fade-right">
                        <li className={styles.bordertitle}>
                            <span>
                                {t("B2B 충전사업 운영자 관리 시스템")}
                            </span>
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("Power usage by period")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 등록 관리 – 주소, 운영자, 이용시간, 요금")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 별 충전기 현황 및 관리")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("평균 가동 현황 및 유휴 시간대")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("서비스 버전 확인, 원격 업데이트")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("장애 관리 – 유형 별 알림, 대시보드, 원격 점검")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("주소, 커넥터 타입, 전력 사용량, 사용 시간")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("관리자 웹/모바일 알림/푸시 서비스")}
                        </li>
                    </ul>
                </div>
            </div>





            <div className={`${styles.csms} ${styles.add_platform_2}`} data-aos="fade-right">
                <div className={`${styles.csms_txt} ${styles.add_platform_2_txt}`}>
                    <div className={styles.csms_title} data-aos="fade-left">
                        <Trans t={t} i18nKey={"CSMS:<1/>사용자 서비스"} components={{ 1: <br/> }}>
                            CSMS:<br/>사용자 서비스
                        </Trans>
                    </div>

                    <ul data-aos="fade-left">
                        <li className={styles.bordertitle}>
                            <span>
                                {t("사용자 Web/Mobile 서비스")}
                            </span>
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 검색, 길찾기 기능")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전소 이용 현황, 충전 가능 상태")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("차량 등록 시스템 및 자동 충전 개시")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전/완충 안내 및 중단 시 사용자 확인 서비스")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("충전기 예약 서비스 및 푸시 알림")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("월별 충전 요금, 전력 사용량 확인")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("결제 내역 및 포인트 충전, 사용 내역 확인")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("멤버십 카드 발급")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("NFC 후불교통카드/삼성페이 결제 지원")}
                        </li>

                        <li>
                            <span className={styles.txt_span}>▪</span> {t("전용 모바일 앱 제공")}
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default CSMS;
