import {useEffect} from 'react';
import styles from '../css/style.module.css';
import CSFDSSection1 from "./platform/CSFDS_Section_1";
import { Trans, useTranslation } from "react-i18next";

const Device = ({makeHeader}) => {
    const Header = makeHeader();
    const { t, i18n } = useTranslation()
    useEffect(() => {
        document.title = t("쿨차지충전기");
    },[t])
    return (
        <div className={styles.device}>
            <section className={styles.main_visual}>
                {Header}

                <div className={styles.main_bg}></div>

                <h2 className={styles.h2}>
                    <div>{t("쿨차지충전기")}</div>
                    {i18n.language !== "en" && <div>KOOLCHARGE Charger</div>}
                </h2>

                <div className={styles.scroll_downs}>
                    <div className={styles.mousey}>
                        <div className={styles.scroller}></div>
                    </div>
                </div>

                <div className={styles.subnav_btn}>
                    <div><a href="#KCA7Series">KCA7P7PG</a></div>
                    <div><a href="#KCA7Series">KCA7 {t("시리즈")}</a></div>
                    <div><a href="#KCA11Series">KCA11 {t("시리즈")}</a></div>
                    <div><a href="#KCD35">KCD35</a></div>
                    <div><a href="#KCD50100">KCD50/100</a></div>
                </div>
            </section>

            <section className={styles.main_container}>
                <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div>{t("독보적 AI 기술로 더욱 스마트한")}</div>
                    <Trans t={t} i18nKey={"EV 충전 솔루션 <1>쿨차지</1>"} components={{ 1: <span/> }}>
                        EV 충전 솔루션 <span>쿨차지</span>
                    </Trans>
                </h3>

                <div className={styles.charger} data-aos="fade-up">
                    <div className={styles.charger_txt_wrap}>
                        <div className={styles.charger_txt} data-aos="fade-down">
                            <ul>
                                <li>
                                    01
                                </li>

                                <li>
                                    {t("나를 알아보는 충전기")}, <span>{t("쿨차지")}</span>
                                </li>

                                <li>
                                    {t("충전기 전면의 고해상도 카메라와 AI기반 분석기술로 내 차 번호판, 내 얼굴을 인식하는똑똑한 충전기")}
                                </li>
                            </ul>
                        </div>

                        <div className={styles.charger_txt} data-aos="fade-down" data-aos-delay="400">
                            <ul>
                                <li>
                                    02
                                </li>

                                <li>
                                    {t("내 차를 지켜주는 충전기")}, <span>{t("쿨차지")}</span>
                                </li>

                                <li>
                                    {t("충전기에 탑재 된 지능형 CCTV가 충전 중 발생하는 돌발 상황을 감지하고 녹화해서 사고, 화재를 감시")}
                                </li>
                            </ul>
                        </div>

                        <div className={styles.charger_txt} data-aos="fade-down" data-aos-delay="800">
                            <ul>
                                <li>
                                    03
                                </li>

                                <li>
                                    {t("동급최강의 탁월한 편의성")}, <span>{t("쿨차지")}</span>
                                </li>

                                <li>
                                    <span className={styles.txt_span}>▪</span> {t("QR코드 및 NFC기반의 간편결제 연동")}
                                </li>

                                <li>
                                    <span className={styles.txt_span}>▪</span> {t("콤팩트한 본체와 저발열 설계")}
                                </li>

                                <li>
                                    <span className={styles.txt_span}>▪</span> {t("전면 개폐 구조로 간편한 A/S")}
                                </li>

                                <li>
                                    <span className={styles.txt_span}>▪</span> {t("쿨차지 전용 모바일앱으로 손쉬운 서비스 업데이트")}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id={"KCA7P7PG"} style={{ marginBottom: "100px" }}>
                    <CSFDSSection1 />
                </div>
                <div className={styles.KCA7Series} id="KCA7Series">
                    <h4 className={styles.h4} data-aos="fade-down" data-aos-offset="300">
                        KCA7
                        <div>series</div>
                    </h4>

                    <div className={styles.KCA7Series_bg} data-aos="fade-down"></div>

                    <div className={styles.KCA7Series_content_1} data-aos="fade-down">
                        <div className={styles.KCA7Series_stand}>
                            <div><img alt="이미지02-04" src="/images/02-04.png" /></div>
                            <div><img alt="이미지02-05" src="/images/02-05.png" /></div>
                        </div>

                        <div className={styles.KCA7Series_stand_txt}>
                            <ul className={styles.KCA7}>
                                <li>
                                    KCA7
                                </li>

                                <li>
                                    {t("비공용 완속 충전기")}
                                </li>

                                <li></li>
                            </ul>

                            <ul className={styles.KCA7P}>
                                <li>
                                    KCA7P
                                </li>

                                <li>
                                    {t("공용 완속 충전기")}
                                </li>

                                <li></li>
                            </ul>
                        </div>

                        <div className={styles.KCA7Series_place}>
                            <div className={styles.KCA7_place} data-aos="fade-down">
                                <img alt="이미지02-06" src="/images/02-06.png" />

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey={"<0>KCA7</0> 충전기는 <0>7KW</0> 용량으로 개인 주택 및 사무실에 설치하여 언제든지 원하는 대로 충전할 수 있는 모델입니다."}>
                                            <span>KCA7</span> 충전기는 <span>7KW</span> 용량으로 개인 주택 및 사무실에 설치하여 언제든지 원하는 대로 충전할 수 있는 모델입니다.
                                        </Trans>

                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 상태 표시 LED (대기/충전/완료/오류)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("단독, 다가구, 공동주택 및 사업장 등의 비과금형 충전용")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("RF 태그를 이용한 사용자 인증")}
                                    </li>
                                </ul>
                            </div>

                            <div className={styles.KCA7P_place} data-aos="fade-down" data-aos-delay="200">
                                <img alt="이미지02-07" src="/images/02-07.png" />

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey={"<0>KCA7P</0> 충전기는 <1>7KW</1> 용량으로 사업장, 공용주차장, 쇼핑몰등에 설치하여 수익 사업이 가능한 모델입니다."}>
                                            <span>KCA7P</span> 충전기는 <span>7KW</span> 용량으로 사업장, 공용주차장, 쇼핑몰등에 설치하여 수익 사업이 가능한 모델입니다.
                                        </Trans>

                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 상태 표시 디스플레이 (8” 컬러 LCD)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("다양한 결제 방식 지원 (멤버십카드, 페이온 후불교통카드, 삼성페이)")}
                                    </li>

                                    {/*<li>*/}
                                    {/*    <span className={styles.txt_span}>▪</span> AI 자동차 번호 인식 기반 자동 충전 기능 (option)*/}
                                    {/*</li>*/}
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className={styles.KCA7Series_content_2} data-aos="fade-up">
                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-01" src="/images/02-set-01.png" /></div>
                            <div><img alt="이미지02-set-02" src="/images/02-set-02.png" /></div>
                            <div><img alt="이미지02-set-03" src="/images/02-set-03.png" /></div>
                        </div>

                        <div className={styles.KCA7Series_table}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>{t("모델명")}</th>
                                        <td>KCA7, KCA7P</td>
                                    </tr>

                                    <tr>
                                        <th>{t("입력")}</th>
                                        <td>1Ø 2W, 220V AC, 60Hz 32A</td>
                                    </tr>

                                    <tr>
                                        <th>{t("출력")}</th>
                                        <td>1Ø 2W, 220V AC, 60Hz 7kW</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증방식")}</th>
                                        <td>{t("RF CARD 및 신용카드 결재")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("충전시간")}</th>
                                        <td>{t("10시간 이내 (64kWh기준)")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("크기")}</th>
                                        <td>264(W) x 408(H) x 97.5(D)mm</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인렛 구조")}</th>
                                        <td>SAE J1772 Type1, 16.8 Ø</td>
                                    </tr>

                                    <tr>
                                        <th>{t("충전 차량")}</th>
                                        <td>{t("표준 5PIN 차량")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증")}</th>
                                        <td>{t("KC / 형식승인 / OCPP 1.6 인증")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("IP등급")}</th>
                                        <td>{t("본체 : IP65 / 커플러 : IP44")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("설치방식")}</th>
                                        <td>{t("월박스 및 스탠드 모두 가능")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-04" src="/images/02-set-04.png" /></div>
                            <div><img alt="이미지02-set-05" src="/images/02-set-05.png" /></div>
                            <div><img alt="이미지02-set-06" src="/images/02-set-06.png" /></div>
                        </div>
                    </div>
                </div>

                <div className={styles.KCA11Series} id="KCA11Series">
                    <h4 className={styles.h4} data-aos="fade-down">
                        KCA11
                        <div>series</div>
                    </h4>

                    <div className={styles.KCA11Series_bg} data-aos="fade-down"></div>

                    <div className={styles.KCA11Series_content_1} data-aos="fade-down">
                        <div className={styles.KCA11Series_stand}>
                            <div><img alt="이미지02-04" src="/images/02-04.png" /></div>
                            <div><img alt="이미지02-05" src="/images/02-05.png" /></div>
                        </div>

                        <div className={styles.KCA11Series_stand_txt}>
                            <ul className={styles.KCA11}>
                                <li>
                                    KCA11
                                </li>

                                <li>
                                    {t("비공용 완속 충전기")}
                                </li>

                                <li></li>
                            </ul>

                            <ul className={styles.KCA11P}>
                                <li>
                                    KCA11P
                                </li>

                                <li>
                                    {t("공용 완속 충전기")}
                                </li>

                                <li></li>
                            </ul>
                        </div>

                        <div className={styles.KCA11Series_place}>
                            <div className={styles.KCA11_place} data-aos="fade-down">
                                <img alt="이미지02-10" src="/images/02-10.png" />

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey={"<0>KCA11</0> 충전기는 <1>11KW</1> 용량으로 개인 주택 및 사무실에 설치하여 언제든지 원하는 대로 충전할 수 있는 모델입니다."}>
                                            <span>KCA11</span> 충전기는 <span>11KW</span> 용량으로 개인 주택 및 사무실에 설치하여 언제든지 원하는 대로 충전할 수 있는 모델입니다.
                                        </Trans>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 상태 표시 LED (대기/충전/완료/오류)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("단독, 다가구, 공동주택 및 사업장 등의 비과금형 충전용")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("RF 태그를 이용한 사용자 인증")}
                                    </li>
                                </ul>
                            </div>

                            <div className={styles.KCA11_place} data-aos="fade-down" data-aos-delay="200">
                                <img alt="이미지02-11" src="/images/02-11.png" />

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey={"<0>KCA11P</0> 충전기는 <1>11KW</1> 용량으로 사업장, 공용주차장, 쇼핑몰등에 설치하여 수익 사업이 가능한 모델입니다."}>
                                            <span>KCA11P</span> 충전기는 <span>11KW</span> 용량으로 사업장, 공용주차장, 쇼핑몰등에 설치하여 수익 사업이 가능한 모델입니다.
                                        </Trans>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 상태 표시 디스플레이 (8” 컬러 LCD)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기 동작상태 음성 안내 스피커")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("다양한 결제 방식 지원 (멤버십카드, 페이온 후불교통카드, 삼성페이)")}
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className={styles.KCA11Series_content_2} data-aos="fade-up">
                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-07" src="/images/02-set-07.png" /></div>
                            <div><img alt="이미지02-set-08" src="/images/02-set-08.png" /></div>
                            <div><img alt="이미지02-set-09" src="/images/02-set-09.png" /></div>
                        </div>

                        <div className={styles.KCA11Series_table}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>{t("모델명")}</th>
                                        <td>KCA11, KCA11P</td>
                                    </tr>

                                    <tr>
                                        <th>{t("입력")}</th>
                                        <td>1Ø 2W, 220V AC, 60Hz 50A </td>
                                    </tr>

                                    <tr>
                                        <th>{t("출력")}</th>
                                        <td>1Ø 2W, 220V AC, 60Hz 11kW</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증방식")}</th>
                                        <td>{t("RF CARD 및 신용카드 결재")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("충전시간")}</th>
                                        <td>{t("6시간 이내 (64kWh기준)")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("크기")}</th>
                                        <td>264(W) x 408(H) x 97.5(D)mm</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인렛 구조")}</th>
                                        <td>SAE J1772 Type1, 20.8 Ø</td>
                                    </tr>

                                    <tr>
                                        <th>{t("충전 차량")}</th>
                                        <td>{t("표준 5PIN 차량")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증")}</th>
                                        <td>{t("KC / 형식승인 / OCPP 1.6 인증")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("IP등급")}</th>
                                        <td>{t("본체 : IP65 / 커플러 : IP44")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("설치방식")}</th>
                                        <td>{t("월박스 및 스탠드 모두 가능")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-10" src="/images/02-set-10.png" /></div>
                            <div><img alt="이미지02-set-11" src="/images/02-set-11.png" /></div>
                            <div><img alt="이미지02-set-12" src="/images/02-set-12.png" /></div>
                        </div>
                    </div>
                </div>

                <div className={styles.KCD35} id="KCD35">
                    <h4 className={styles.h4} data-aos="fade-down">
                        KCD35
                    </h4>

                    <div className={styles.KCD35_bg} data-aos="fade-down"></div>

                    <div className={styles.KCD35_content_1} data-aos="fade-down">
                        <div className={styles.KCD35_stand}>
                            <div><img alt="이미지02-13" src="/images/02-13.png" /></div>
                            <div><img alt="이미지02-14" src="/images/02-14.png" /></div>
                        </div>

                        <div className={styles.KCD35_stand_txt}>
                            <ul className={styles.KCD35_nm}>
                                <li>
                                    KCD35
                                </li>

                                <li></li>

                                <li>
                                    {t("중속 충전기")}
                                </li>
                            </ul>
                        </div>

                        <div className={styles.KCD35_place}>
                            <div className={styles.KCD35_place_inner} data-aos="fade-down">
                                <img alt="이미지02-15" src="/images/02-15.jpg" />

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey={"<0>KCD35</0> 충전기는 <0>35kW</0> 용량의 중속 충전기로 백화점, 대형 마트, 쇼핑몰 등의 고객 이용 패턴에 최적화된 모델입니다."}>
                                            <span>KCD35</span> 충전기는 <span>35kW</span> 용량의 중속 충전기로 백화점, 대형 마트, 쇼핑몰 등의 고객 이용 패턴에 최적화된 모델입니다.
                                        </Trans>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 상태 표시 디스플레이 (12” 컬러 LCD)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기 동작상태 음성 안내 스피커")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("다양한 결제 방식 지원 (멤버십카드, 신용카드, 삼성페이)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("AI기반 자동차 번호 인식 및 화재 감시 기능")}
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={styles.KCA7Series_content_2} data-aos="fade-up">
                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-13" src="/images/02-set-13.png" /></div>
                            <div><img alt="이미지02-set-14" src="/images/02-set-14.png" /></div>
                            <div><img alt="이미지02-set-15" src="/images/02-set-15.png" /></div>
                        </div>

                        <div className={styles.KCA7Series_table}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>{t("모델명")}</th>
                                        <td>KCD35</td>
                                    </tr>

                                    <tr>
                                        <th>{t("입력")}</th>
                                        <td>3Ø 4W, 380V AC, 60Hz, 37kW</td>
                                    </tr>

                                    <tr>
                                        <th>{t("출력")}</th>
                                        <td>DC 150~1000V, Max.80A, 35kW</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증방식")}</th>
                                        <td>{t("RF CARD 및 신용카드 결재")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("효율 / 역율")}</th>
                                        <td>{t("95% 이상 / 0.95 이상")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("크기")}</th>
                                        <td>676(W) x 760(H) x 273(D)mm</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인렛 구조")}</th>
                                        <td>CCS Type 1</td>
                                    </tr>

                                    <tr>
                                        <th>{t("충전 차량")}</th>
                                        <td>{t("표준 5PIN 차량")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증")}</th>
                                        <td>{t("KC / 형식승인 / OCPP 1.6 인증")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("IP등급")}</th>
                                        <td>{t("본체 : IP65 / 커플러 : IP44")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("통신방식")}</th>
                                        <td>4G LTE Cat 4</td>
                                    </tr>

                                    <tr>
                                        <th>{t("차단기 용량")}</th>
                                        <td>{t("누전차단기 80A , 30mA")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-16" src="/images/02-set-16.png" /></div>
                            <div><img alt="이미지02-set-17" src="/images/02-set-17.png" /></div>
                            <div><img alt="이미지02-set-18" src="/images/02-set-18.png" /></div>
                        </div>
                    </div>
                </div>

                <div className={styles.KCD50100} id="KCD50100">
                    <h4 className={styles.h4} data-aos="fade-down">
                        KCD<br/>
                        50/100
                    </h4>

                    <div className={styles.KCD50100_bg} data-aos="fade-down"></div>

                    <div className={styles.KCD50100_content_1} data-aos="fade-down">
                        <div className={styles.KCD50100_stand}>
                            <div><img alt="이미지02-17" src="/images/02-17.png" /></div>
                        </div>

                        <div className={styles.KCD50100_stand_txt}>
                            <ul className={styles.KCD50100_nm}>
                                <li>
                                    KCD50/100
                                </li>

                                <li></li>

                                <li>
                                    {t("급속 충전기")}
                                </li>
                            </ul>
                        </div>

                        <div className={styles.KCD50100_place}>
                            <div className={styles.KCD50100_place_inner} data-aos="fade-down">
                                <img alt="이미지02-18" src="/images/02-18.jpg" />

                                <ul>
                                    <li>
                                        <Trans t={t} i18nKey={"<0>KCD50/100</0> 충전기는 <0>50/100kW</0> 용량의 급속 2채널 충전기로 전용 충전소, 사옥, 주차장, 백화점, 대형 마트, 쇼핑몰 등 모든 장소에 설치 가능한 모델입니다."}>
                                            <span>KCD50/100</span> 충전기는 <span>50/100kW</span> 용량의 급속 2채널 충전기로 전용 충전소, 사옥, 주차장, 백화점, 대형 마트, 쇼핑몰 등 모든 장소에 설치 가능한 모델입니다.
                                        </Trans>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 상태 및 광고 디스플레이 (27” 터치스크린)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기 동작상태 음성 안내 스피커")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("2채널 스마트 충전")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("AI기반 자동차 번호 인식 및 화재 감시 기능")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("다양한 결제 방식 지원 (멤버십카드, 신용카드, 삼성페이)")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={styles.KCA7Series_content_2} data-aos="fade-up">
                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-19" src="/images/02-set-19.png" /></div>
                            <div><img alt="이미지02-set-20" src="/images/02-set-20.png" /></div>
                            <div><img alt="이미지02-set-21" src="/images/02-set-21.png" /></div>
                        </div>

                        <div className={styles.KCA7Series_table}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>{t("모델명")}</th>
                                        <td>KCD50</td>
                                        <td>KCD100</td>
                                    </tr>

                                    <tr>
                                        <th>{t("입력")}</th>
                                        <td>3Ø 4W, 380V AC, 60Hz, 53kW</td>
                                        <td>3Ø 4W, 380V AC, 60Hz, 100kW</td>
                                    </tr>

                                    <tr>
                                        <th>{t("출력")}</th>
                                        <td>
                                            {t("1CH 충전")}<br/>DC 150~1000V, Max.150A, 50kW<br/>
                                            {t("2CH 동시 충전")}<br/>DC 150~1000V, Max.150A, 25kW x 2
                                        </td>
                                        <td>
                                            {t("1CH 충전")}<br/>DC 150~1000V, Max.200A, 100kW<br/>
                                            {t("2CH 동시 충전")}<br/>DC 150~1000V, Max.200A, 50kW x 2
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증방식")}</th>
                                        <td colSpan={2}>{t("RF CARD 및 신용카드 결재")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("효율 / 역율")}</th>
                                        <td colSpan={2}>{t("95% 이상 / 0.95 이상")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("크기")}</th>
                                        <td>780(W) x 1600(H) x 400(D)mm</td>
                                        <td>780(W) x 1800(H) x 400(D)mm</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인렛 구조")}</th>
                                        <td colSpan={2}>CCS Type 1</td>
                                    </tr>

                                    <tr>
                                        <th>{t("충전 차량")}</th>
                                        <td colSpan={2}>{t("표준 5PIN 차량")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("인증")}</th>
                                        <td colSpan={2}>{t("KC / 형식승인 / OCPP 1.6 인증")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("IP등급")}</th>
                                        <td colSpan={2}>{t("본체 : IP44 / 커플러 : IP54")}</td>
                                    </tr>

                                    <tr>
                                        <th>{t("통신방식")}</th>
                                        <td colSpan={2}>4G LTE Cat 4</td>
                                    </tr>

                                    <tr>
                                        <th>{t("차단기 용량")}</th>
                                        <td>{t("누전차단기 100A , 30mA")}</td>
                                        <td>{t("누전차단기 200A , 30mA")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={styles.content_2_set}>
                            <div><img alt="이미지02-set-22" src="/images/02-set-22.png" /></div>
                            <div><img alt="이미지02-set-23" src="/images/02-set-23.png" /></div>
                            <div><img alt="이미지02-set-24" src="/images/02-set-24.png" /></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Device;
