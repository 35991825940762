import menuals_json from './menual.json'
import { useEffect,useState } from 'react';
import { useParams,Link } from "react-router-dom";
import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

function Menual({makeHeader}) {
    const { id } = useParams();
    const { t } = useTranslation();
    const [menual, setMenual] = useState([]);
    const Header = makeHeader();

    const getMenual = () => {
        setMenual(menuals_json[id]);
    }

    useEffect(() => {
        getMenual();
    },[])

    return<div>
        {Header}
        <div className={styles.main_container}>
            <div className={styles.support}>
                <h2>{t("자료실")}</h2>
                <div className={styles.board}>
                    <div>
                        <p style={{fontWeight:600}}>{menual['title']}</p>
                        <span style={{fontWeight:600}}>{t("작성자")}</span> {t("관리자")}
                        <span style={{marginLeft:"15px",fontWeight:600}}>{t("등록일")}</span> {menual['created_at']}
                    </div>
                    <div style={{whiteSpace: "pre-wrap"}}>
                        {
                            typeof menual.desc === 'string' ? menual['desc'] : menual.blocks?.map(block => <>
                                <div>
                                    {block.link ? <a target="_blank" style={{color:"#F16722", fontSize:16, textDecoration:"underline"}} href={block.link}>{block.desc}</a> : <p>{block.desc}</p>}
                                </div>
                            </>)
                        }
                    </div>
                    <div>
                        <a href={menual['file_path']}>
                            {menual['filename']}
                        </a>
                    </div>
                </div>
                <div style={{margin:"30px auto", width:"80px", height:"50px"}}>
                    <Link to="/support/menuals">
                        <button className={styles.contact_submit} >
                            {t("목록")}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    </div> 
}

export default Menual
