import { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CSMS from './platform/CSMS';
import CSIS from './platform/CSIS';
import CSSS from './platform/CSSS';
import CSFDS_new from "./platform/CSFDS_new";
import styles from '../css/style.module.css';
import { useTranslation } from "react-i18next";

const Platform = ({makeHeader}) => {

    const Header = makeHeader();
    const [selectedTab, setSelectedTab] = useState('CSMS');
    const location = useLocation()
    const { t, i18n } = useTranslation();

    const clickTabEvent = (type) => {
        setSelectedTab(type)
    }

    useEffect(() => {
        document.title = t("쿨차지플랫폼")
        let hash = location.hash

        console.log(location.hash)

        if(hash)
            setSelectedTab(hash.replace("#",''))
        else
            setSelectedTab("CSMS")
    },[location.hash, t])

    return (
        <div className={styles.platform}>
            <section className={styles.main_visual}>
                {Header}
                
                <div className={styles.main_bg}></div>

                <h2 className={styles.h2}>
                    <div>{t("쿨차지플랫폼")}</div>
                    {i18n.language !== "en" && <div>KOOLCHARGE Platform</div>}
                </h2>

                <div className={styles.scroll_downs}>
                    <div className={styles.mousey}>
                        <div className={styles.scroller}></div>
                    </div>
                </div>

                <div className={styles.subnav_btn +' '+ styles.subnav_btn_full}>
                    <div style={selectedTab === 'CSMS' ? {background:"#F16722"}:{}} onClick={()=>{clickTabEvent('CSMS')}}><a href="#CSMS">{t("충전소 관리 시스템 (CSMS)")}</a></div>
                    <div style={selectedTab === 'CSFDS' ? {background:"#F16722"}:{}} onClick={()=>{clickTabEvent('CSFDS')}}><a href="#CSFDS">{t("충전소 화재감시 시스템 (CSFDS)")}</a></div>
                    <div style={selectedTab === 'CSIS' ? {background:"#F16722"}:{}} onClick={()=>{clickTabEvent('CSIS')}}><a href="#CSIS">{t("충전소 통합정보 시스템 (CSIS)")}</a></div>
                    <div style={selectedTab === 'CSSS' ? {background:"#F16722"}:{}} onClick={()=>{clickTabEvent('CSSS')}}><a href="#CSSS">{t("충전소 보안 시스템 (CSSS)")}</a></div>
                </div>
            </section>

            {
                selectedTab === 'CSMS' ? <CSMS/> :
                selectedTab === 'CSFDS' ? <CSFDS_new/> :
                selectedTab === 'CSIS' ? <CSIS/> :
                selectedTab === 'CSSS' ? <CSSS/> : <></>
            }

            {/* <section className={styles.main_container}>
                <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                    충전관리시스템 (CSMS)
                </h3>

                <div className={`${styles.csms} ${styles.csms_1}`} data-aos="fade-left">
                    <div className={`${styles.csms_txt} ${styles.csms_txt_1}`}>
                        <div className={styles.csms_title} data-aos="fade-right">
                            충전사업운영자 관리시스템
                        </div>

                        <ul data-aos="fade-right">
                            <li>
                                <span className={styles.txt_span}>▪</span> 충전소 등록 관리 – 주소, 운영자, 이용시간, 요금
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 충전소 별 충전기 현황 및 관리
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 서비스 버전 확인, 원격 업데이트
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 장애 관리, 원격 점검
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 관리자 웹/모바일 알림/푸시 서비스
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={`${styles.csms} ${styles.csms_2}`} data-aos="fade-right">
                    <div className={`${styles.csms_txt} ${styles.csms_txt_2}`}>
                        <div className={styles.csms_title} data-aos="fade-left">
                            사용자 관리시스템 (web/mobile App.)
                        </div>

                        <ul data-aos="fade-left">
                            <li>
                                <span className={styles.txt_span}>▪</span> 충전소 등록 관리 – 주소, 운영자, 이용시간, 요금
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 충전소 별 충전기 현황 및 관리
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 서비스 버전 확인, 원격 업데이트
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 장애 관리, 원격 점검
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 관리자 웹/모바일 알림/푸시 서비스
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={`${styles.csms} ${styles.csms_3}`} data-aos="fade-left">
                    <div className={`${styles.csms_txt} ${styles.csms_txt_3}`}>
                        <div className={styles.csms_title} data-aos="fade-right">
                            AI 충전소 화재 감시 시스템
                        </div>

                        <img alt="03-03" src="/images/03-03.png" data-aos="fade-right" />

                        <ul data-aos="fade-right">
                            <li>
                                <span className={styles.txt_span}>▪</span> AI 객체탐지 + 지능형 CCTV로 화재 감시
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 설정 온도 초과시 관리자 경고
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> AI 화재 탐지시 관리자 경고
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 지역별 감시 온도 설정
                            </li>

                            <li>
                                <span className={styles.txt_span}>▪</span> 웹/모바일 관제 서비스 지원
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={`${styles.csms} ${styles.csms_4}`}>
                    <div className={`${styles.csms_txt} ${styles.csms_txt_4}`}>
                        <div className={styles.csms_title} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                            충전정보 디지털 사이니지
                        </div>

                        <div className={styles.csms_4_wrap}>
                            <div className={styles.csms_4_1}>
                                <div className={styles.csms_4_1_img} data-aos="fade-right"></div>
                                <div className={styles.csms_4_1_list} data-aos="fade-left">
                                    <ul>
                                        <li>
                                            종합 충전 정보 디스플레이 관리
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 충전소 디스플레이 (LCD, 전광판)
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 충전소 안내, 충전기 상태 안내 디스플레이
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 충전기 디스플레이
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 충전기 상태, 충전 정보, 예약 정보 관리
                                        </li>
                                    </ul>
                                </div>
                            </div>
        
                            <div className={styles.csms_4_2}>
                                <div className={styles.csms_4_2_list} data-aos="fade-right">
                                    <ul>
                                        <li>
                                            디지털 콘텐츠 관리 시스템
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 동영상/이미지/HTML 등 다양한 콘텐츠 지원
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 콘텐츠 관리 및 광고 스케줄 관리 기능
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 충전기 유휴 시간 광고 편성 기능
                                        </li>
                
                                        <li>
                                            <span className={styles.txt_span}>▪</span> 충전기-디지털 사이니지 통합 관리 지원
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className={styles.csms_4_2_img} data-aos="fade-left"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    );
};

export default Platform;
