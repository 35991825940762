import menuals_json from './menual.json'
import { useEffect,useState } from 'react';
import {Link} from "react-router-dom"
import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

function Menuals({makeHeader}) {
    const [menuals, setMenuals] = useState([]);
    const Header = makeHeader();
    const { t } = useTranslation();

    const getMenuals = () => {
        let keys = Object.keys(menuals_json);
        setMenuals(keys.map(key => [key, menuals_json[key]])) 
    }

    useEffect(() => {
        getMenuals();
    },[])

    return<div>
        {Header}
        <div className={styles.main_container}>
            <div className={styles.support}>
                <h2>{t("자료실")}</h2>
                <p><span>{menuals.length}</span> {t("항목")}</p>
                <table>
                    <thead>
                        <tr>
                            <th>NO.</th>
                            <th>{t("제목")}</th>
                            <th>{t("작성자")}</th>
                            <th>{t("등록일")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            menuals.map((menual) => {
                                return <tr key={menual[0]} style={menual[0] === '0' ? {background:"rgba(241,103,34,0.1)"} : null}>
                                    <td>{parseInt(menual[0]) ? menual[0] : ''}</td>
                                    <td><Link to={'/support/menual/'+menual[0]}>{menual[1]['title']}</Link></td>
                                    <td>{t("관리자")}</td>
                                    <td>{menual[1]['created_at']}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div> 
}

export default Menuals
