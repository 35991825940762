class Installation {
    constructor(httpClient) {
        this.http = httpClient;
    }

    async postInquiry(name, phone, email, address, location, environment, park_side, open_type, reason) {
        return this.http.fetch('https://admin-server.koolcharge.com/etc/request_install', {
            method: 'POST',
            body: JSON.stringify({ name, phone, email, address, location, environment, park_side, open_type, reason }),
        });
    }
}

export default Installation;