import React, { useEffect, useRef } from "react";
import { Switch, Route, useHistory } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Device from './components/device';
import Inquiry from './components/installation/inquiry';
import Procedure from './components/installation/procedure';
import Footer from './components/layout/footer';
import Header from './components/layout/header';
import Main from './components/main';
import Platform from './components/platform';
import styles from './css/style.module.css'; 
import Menuals from './components/support/menuals';
import Menual from './components/support/menual.jsx';
import Inquiries from './components/support/inquiries';
import Inquiry_ from './components/support/inquiry';
import PostInquery from "./components/support/postInquiry";

function App({installation}) {
    useEffect(() => {
        AOS.init({
            duration: 500,
            easing: 'ease-in-sine'
        });
    }, []);

    const history = useHistory();
    const scrollRef = useRef();
    const onMain = () => history.push('/');
    const onDevice = () => history.push('/device');
    const onPlatform = () => history.push('/platform#CSMS');
    const onProcedure = () => history.push('/installation/procedure');
    const onInquiry = () => history.push('/installation/inquiry');
    const makeHeader = () => <Header onMain={onMain} onDevice={onDevice} onPlatform={onPlatform} onProcedure={onProcedure} onInquiry={onInquiry} />

    return (
        <div ref={scrollRef} className={styles.wrap}>
            <Switch>
                <Route exact path='/'>
                    <Main makeHeader={makeHeader} onInquiry={onInquiry} onDevice={onDevice} />
                </Route>
                <Route exact path='/device'>
                    <Device makeHeader={makeHeader} />
                </Route>
                <Route exact path='/platform'>
                    <Platform makeHeader={makeHeader} />
                </Route>
                <Route exact path='/installation/procedure'>
                    <Procedure makeHeader={makeHeader} onInquiry={onInquiry} />
                </Route>
                <Route exact path='/installation/inquiry'>
                    <Inquiry makeHeader={makeHeader} onProcedure={onProcedure} installation={installation} />
                </Route>
                <Route exact path='/support/menuals'>
                    <Menuals makeHeader={makeHeader} />
                </Route>
                <Route exact path='/support/menual/:id'>
                    <Menual makeHeader={makeHeader} />
                </Route>
                <Route exact path='/support/inquiries'>
                    <Inquiries makeHeader={makeHeader} />
                </Route>
                <Route exact path='/support/inquiries/create'>
                    <PostInquery makeHeader={makeHeader} />
                </Route>
                <Route exact path='/support/inquiry/:id'>
                    <Inquiry_ makeHeader={makeHeader} />
                </Route>
            </Switch>
            <Footer scrollRef={scrollRef} />
        </div>
    );
}

export default App;
