import {useEffect} from 'react';
import styles from '../css/style.module.css';
import {Link} from "react-router-dom"
import { useTranslation } from "react-i18next";

const Main = ({makeHeader, onInquiry, onDevice}) => {
    const Header = makeHeader();
    const { t } = useTranslation()

    useEffect(() => {
        document.title = t("쿨차지");
    },[t])

    return (
        <section className={styles.main_visual}>
            {Header}

            <div className={styles.video_wrap}>
                <video muted autoPlay loop>
                    <source src="/images/main.mp4" type="video/mp4" />
                </video>
            </div>

            <h1 className={styles.h1}>
                ONE-STOP TOTAL<br />
                <span>EV</span> CHARGE SOLUTION<br />
                <span>KOOLCHARGE</span>
            </h1>

            <div className={styles.mainBtn_wrap}>
                {/*<Link to="/installation/inquiry">*/}
                {/*    <img alt="메인-1" src="/images/main-1.png" className={styles.mainBtn_i} />*/}
                {/*    <img alt="메인-1-h" src="/images/main-1-h.png" className={styles.mainBtn_i_h} />*/}

                {/*    <div className={styles.mainBtn_line2}>*/}
                {/*        <div className={styles.mainBtn_txt}>충전기 설치 문의</div>*/}
                {/*        <img alt="arrow-r" src="/images/arrow-r.png" className={styles.mainBtn_ar1} />*/}
                {/*    </div>*/}
                {/*</Link>*/}
                <Link to="/device">
                    <img alt="메인-3" src="/images/main-3.png" className={styles.mainBtn_i} />
                    <img alt="메인-3h" src="/images/main-3-h.png" className={styles.mainBtn_i_h} />

                    <div className={styles.mainBtn_line2}>
                        <div className={styles.mainBtn_txt}>{t("쿨차지충전기")}</div>
                        <img alt="arrow-r" src="/images/arrow-r.png" className={styles.mainBtn_ar2} />
                    </div>
                </Link>

                <Link to="/platform#CSFDS">
                    <img alt="메인-3" src="/images/main-1.png" className={styles.mainBtn_i} />
                    <img alt="메인-3h" src="/images/main-1-h.png" className={styles.mainBtn_i_h} />

                    <div className={styles.mainBtn_line2}>
                        <div className={styles.mainBtn_txt}>{t("충전소 화재감지")}</div>
                        <img alt="arrow-r" src="/images/arrow-r.png" className={styles.mainBtn_ar2} />
                    </div>
                </Link>
                <a href="#download">
                    <img alt="메인-3" src="/images/main-install.png" className={styles.mainBtn_i} />
                    <img alt="메인-3h" src="/images/main-install-h.png" className={styles.mainBtn_i_h} />

                    <div className={styles.mainBtn_line2}>
                        <div className={styles.mainBtn_txt}>{t("쿨차지 앱 다운로드")}</div>
                        <img alt="arrow-r" src="/images/arrow-r.png" className={styles.mainBtn_ar3} />
                    </div>
                </a>
            </div>

            <div className={styles.app_link_section} id={"download"}>
                <h3>{t("쿨차지 앱 다운로드")}</h3>
                <div className={styles.app_link_container}>
                    <div className={styles.app_link}>
                        <img className={styles.app_link_qr} alt={"qr"} src={"/images/google_play_qr.svg"}/>
                        <a className={styles.app_link_badge} href={"https://play.google.com/store/apps/details?id=net.koolsign.charge_app"} target={"_blank"}>
                            <img className={styles.app_link_badge_image} alt={"google"} src={"/images/google_play_badge.svg"}/>
                        </a>
                    </div>
                    <div className={styles.app_link}>
                        <img className={styles.app_link_qr} alt={"qr"} src={"/images/apple_store_qr.svg"}/>
                        <a className={styles.app_link_badge} href={"https://apps.apple.com/us/app/koolcharge-%EC%BF%A8%EC%B0%A8%EC%A7%80-%EB%82%98%EB%A5%BC-%EC%95%8C%EC%95%84%EB%B3%B4%EB%8A%94-ai%EC%B6%A9%EC%A0%84/id1663150470"} target={"_blank"}>
                            <img className={styles.app_link_badge_image} alt={"apple"} src={"/images/apple_store_badge.svg"}/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Main;
