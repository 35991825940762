import styles from "../../css/csdfs.module.css";
import CSFDSSection1 from "./CSFDS_Section_1";
import CSFDSSection2 from "./CSFDS_Section_2";
import CSFDSSection3 from "./CSFDS_Section_3";
import { useTranslation } from "react-i18next";

function CSFDS(props) {

    const { t } = useTranslation();

    return (
        <div
            className={styles.container}>
            <h3
                className={styles.mainTitle}
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
            >
                {t("충전소 화재감시 시스템 (CSFDS)")}
            </h3>
            <CSFDSSection1/>
            <CSFDSSection2/>
            <CSFDSSection3/>
        </div>
    );

}

export default CSFDS;
