import {useEffect} from 'react';
import styles from '../../css/style.module.css'; 
import {Link} from "react-router-dom"

const Procedure = ({makeHeader, onInquiry}) => {
    const Header = makeHeader();
    useEffect(() => {
        document.title = "설치절차"
    },[])
    return (
        <div className={styles.procedure}>
            <section className={styles.main_visual}>
                {Header}
                
                <div className={styles.main_bg}></div>

                <h2 className={styles.h2}>
                    <div>설치절차</div>
                    <div>Application For Installation</div>
                </h2>

                <div className={styles.scroll_downs}>
                    <div className={styles.mousey}>
                        <div className={styles.scroller}></div>
                    </div>
                </div>

                <div className={styles.subnav_btn}>
                    <div><Link to="/installation/inquiry">설치신청</Link></div>
                    <div><Link to="/installation/procedure">설치절차</Link></div>
                </div>
            </section>

            <section className={styles.main_container}>
                <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                    설치절차
                </h3>

                <div className={styles.process_wrap} data-aos="fade-up" data-aos-anchor-placement="top-center">
                    <div className={styles.process_1}>
                        <div></div>
                    </div>

                    <div className={styles.process_2}>
                        <ul>
                            <li>
                                <span className={styles.txt_span}>▪</span> 언제든지 자유롭게 신청문의를 남겨주세요
                            </li>
                            <li>
                                <span className={styles.txt_span}>▪</span> 담당자가 직접 연락을 드립니다. 설치관련 질의응답과 실사일정을 조율합니다.
                            </li>
                            <li>
                                <span className={styles.txt_span}>▪</span> 현장에 방문하여 분전함위치, 주차장환경을 조사하여 최적의 설치장소를 제안드립니다.
                            </li>
                            <li>
                                <span className={styles.txt_span}>▪</span> 충전기를 확정하고 환경부 보조금 승인을 위한 자격서류 제출을 도와드립니다.
                            </li>
                            <li>
                                <span className={styles.txt_span}>▪</span> 예약된 일정에 방문하여 전기공사와 충전기 설치를 진행합니다.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Procedure;