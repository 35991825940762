import axios from "axios"
import { useHistory } from 'react-router-dom';
import {useState} from 'react';
import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

function PostInquery({makeHeader}) {
    const { t } = useTranslation();
    const Header = makeHeader();
    const history = useHistory()
    const [form, setForm] = useState({
        name:"",
        title:"",
        context:"",
        type:""
    })

    const create = async () => {
        if(form.name === '') {
            alert("이름을 입력해주세요.")
        } else if(form.title === '') {
            alert("제목을 입력해주세요.")
        } else if(form.type === '') {
            alert("문의 유형을 입력해주세요.")
        } else if(form.context === '') {
            alert("문의 내용을 입력해주세요.")
        } else {
            try{
                await axios.post(`/etc/main_inquiry_board/create`,form);
                alert("문의가 등록 되었습니다.");
                history.push('/support/inquiries')
            } catch(e) {
                alert("등록중 오류가 발생하였습니다.")
            }
        }
    }

    const changeEvent = (e) => {
        let json = JSON.parse(JSON.stringify(form));
        json[e.target.name] = e.target.value;
        setForm(json);
    }
    
    return(
        <div>
            {Header}
            <div className={styles.main_container}>
                <div className={styles.support}>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t("이름")}</th>
                                <td><input style={{width:"100%", padding:"3px 0"}} type="text" onChange={changeEvent} value={form.name} name="name"  /></td>
                            </tr>
                            <tr>
                                <th>{t("제목")}</th>
                                <td><input style={{width:"100%", padding:"3px 0"}} type="text" onChange={changeEvent} value={form.title} name="title"  /></td>
                            </tr>
                            <tr>
                                <th>{t("문의 유형")}</th>
                                <select name="type" style={{width:"100%", margin:"10px 0"}} onChange={changeEvent}>
                                    <option value="">{t("분류")}</option>
                                    <option value="제품문의">{t("제품문의")}</option>
                                    <option value="설치문의">{t("설치문의")}</option>
                                    <option value="기타">{t("기타")}</option>
                                </select>
                            </tr>
                            <tr>
                                <th>{t("문의 내용")}</th>
                                <td><textarea style={{marginBottom: "0px" ,resize:"none", height:"300px"}} onChange={changeEvent} value={form.context} name="context"  /></td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <button onClick={create} className={styles.create_post_button} >
                            {t("등록")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostInquery
