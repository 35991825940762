import React, { useState, useEffect } from 'react';
// import DaumPostcode from 'react-daum-postcode';
import styles from '../../css/style.module.css'; 
import {Link} from "react-router-dom"
import axios from 'axios';

const Inquiry = ({makeHeader, onProcedure, installation}) => {
    const Header = makeHeader();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('서울, 경기, 인천');
    const [location, setLocation] = useState('');
    const [environment, setEnvironment] = useState('');
    const [park_side, setParkSide] = useState('');
    const [open_type, setOpenType] = useState('');
    const [reason, setReason] = useState('');
    const [check, setCheck] = useState(false);
    
    // 팝업창 상태 관리
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // 팝업창 열기
    const openPostCode = (event) => {
        event.preventDefault();
        setIsPopupOpen(true);
    }
    // 팝업창 닫기
    const closePostCode = () => {
        setIsPopupOpen(false);
    };

    const handlePostCode = data => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }
        console.log(fullAddress);
        setAddress(fullAddress);
        closePostCode();
    };

    const onChange = event => {
        const { target: { name, value } } = event;
        switch (name) {
            case 'name': return setName(value);
            case 'phone': return setPhone(value);
            case 'email': return setEmail(value);
            case 'address': return setAddress(value);
            case 'location': return setLocation(value);
            case 'environment': return setEnvironment(value);
            case 'park_side': return setParkSide(value);
            case 'open_type': return setOpenType(value);
            case 'reason': return setReason(value);
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        
        if(!check) {
            alert("개인정보 처리방침에 동의해주세요.")
        } else if (window.confirm('제출하시겠습니까?')) {
            // installation
            //     .postInquiry(name, phone, email, address, location, environment, Number(park_side), open_type, reason)
            //     .then(data => {
            //         if (data == null || data.result === false) {
            //             console.log('error', data);
            //             alert('제출에 실패하였습니다.');
            //         } else if (data.result === true) {
            //             alert('제출하였습니다.');
            //             event.target.reset();
            //             setAddress('');
            //         }
            //     })
            //     .catch((error) => {
            //         alert('서버 오류가 발생했습니다.');
            //         console.log('error:', error.toString());
            //     });
            try {
                await axios.post('/etc/request_install',{ 
                    name, 
                    phone, 
                    email, 
                    address, 
                    location, 
                    environment, 
                    park_side:Number(park_side), 
                    open_type, 
                    reason 
                })
                alert('제출하였습니다.');
                event.target.reset();
                setAddress('');
            } catch(e) {
                alert('서버 오류가 발생했습니다.');
                console.log('error:', e.toString());
            }
        }
    };

    const onChangeRadio = (e) => {
        setAddress(e.target.value)
    } 

    const iframePrivacyPart = () => {
        return {
            __html:'<iframe src="/개인정보처리방침.html" width="100%" height="600px"></iframe>'
        }
    }

    const changeCheck = () => {
        setCheck(!check);
    }

    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    useEffect(() => {
        document.title = "설치신청"

        if(isMobile())
            window.scrollTo(0, 600);
    },[])
 
    return (
        <div className={styles.inquiry}>
            <section className={styles.main_visual}>
                {Header}
                
                <div className={styles.main_bg}></div>

                <h2 className={styles.h2}>
                    <div>설치신청</div>
                    <div>Application For Installation</div>
                </h2>

                <div className={styles.scroll_downs}>
                    <div className={styles.mousey}>
                        <div className={styles.scroller}></div>
                    </div>
                </div>

                <div className={styles.subnav_btn}>
                    <div><Link to="/installation/inquiry">설치신청</Link></div>
                    <div><Link to="/installation/procedure">설치절차</Link></div>
                </div>
            </section>

            <form onSubmit={onSubmit}>
                <section className={styles.main_container}>
                    <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-locationment="top-center" data-aos-offset="-550">
                        설치신청
                    </h3>

                    <h4 className={styles.inquiry_subtitle}>충전기 설치 신청을 해주시면 <br/> 담당자가 신속히 연락 드리겠습니다.</h4>

                    <div className={styles.tel_number}>
                        <img src="/images/phone.png"/>
                        <a href="tel:1600-4045">1600-4045</a>
                    </div>

                    <div className={styles.contact_wrap} data-aos="fade-up" data-aos-anchor-locationment="top-center">
                        <div className={styles.contact_form}>
                            <input type="text" name='name' onChange={onChange} placeholder="성명" required />
                            <div>
                                <input type="tel" name='phone' onChange={onChange} placeholder="연락처" required />
                                <p>- 를 제외한 연락처를 입력해주세요</p>
                            </div>
                        </div>
            
                        {/* <div className={styles.contact_form_2}>
                            <input type="email"  name='email' onChange={onChange} placeholder="이메일" required />
                            <div>
                                <input type="text" name='address' onChange={onChange} value={address} placeholder="설치주소" required />
                                <button onClick={openPostCode}>주소찾기</button>
                            </div>
                        </div> */}
                        {/* <div>
                            {isPopupOpen && (
                                <div className={styles.info_popup}>
                                    <DaumPostcode autoClose onComplete={handlePostCode} className={styles.info_daumPostcode} />
                                    <button type='button' onClick={closePostCode}><img alt="close" src='/images/close.png' className={styles.info_close_img}></img></button>
                                </div>
                            )}
                        </div> */}

                        <h2 style={{fontSize:"20px"}}>설치장소</h2>
                        <div className={styles.radio_wrap}>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="서울, 경기, 인천"} type="radio" id="1" name="address" value="서울, 경기, 인천"/>
                                <label htmlFor="1">서울, 경기, 인천</label>&nbsp;
                            </div>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="강원도"} type="radio" id="2" name="address" value="강원도"/>
                                <label htmlFor="2">강원도</label>&nbsp;
                            </div>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="충청도"} type="radio" id="3" name="address" value="충청도"/>
                                <label htmlFor="3">충청도</label>&nbsp;
                            </div>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="전라도"} type="radio" id="4" name="address" value="전라도"/>
                                <label htmlFor="4">전라도</label>&nbsp;
                            </div>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="경상도"} type="radio" id="5" name="address" value="경상도"/>
                                <label htmlFor="5">경상도</label>&nbsp;
                            </div>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="제주도"} type="radio" id="6" name="address" value="제주도"/>
                                <label htmlFor="6">제주도</label>&nbsp;
                            </div>
                            <div style={{fontSize:"30px"}}>
                                <input onChange={onChangeRadio} checked={address==="기타"} type="radio" id="7" name="address" value="기타"/>
                                <label htmlFor="7">기타</label>&nbsp;
                            </div>
{/*                 
                            <select name='environment' onChange={onChange} required>
                                <option value="">설치환경</option>
                                <option value="개인주택">개인주택</option>
                                <option value="사무실">사무실</option>
                                <option value="사업장">사업장</option>
                                <option value="공용주차장">공용주차장</option>
                                <option value="쇼핑몰">쇼핑몰</option>
                                <option value="기타">기타</option>
                            </select> */}
                        </div>
                        <div className={styles.privacy}>
                            <div style={{border:"3px solid black"}} dangerouslySetInnerHTML={iframePrivacyPart()}/>
                        </div>
            
                        <div className={styles.contact_form_c}>
                            <input value={check} onChange={changeCheck} type="checkbox" id="contact_c" name="contact" /><label htmlFor='contact_c'><i></i></label>
                            <span>개인정보처리방침 동의</span>
                        </div>

                        <input type="submit" value="제출하기" className={styles.contact_submit} />
                    </div>
                </section>
            </form>
        </div>
    )
};

export default Inquiry;