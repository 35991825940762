import styles from "../../css/csdfs.module.css";
import { useTranslation } from "react-i18next";

function CSFDSSection1(props) {

    const { t } = useTranslation();

    return (
        <section className={styles.section}>
            <h4
                className={styles.sectionTitle}
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
            >
                {t("화재 예방 충전기")}
            </h4>
            <div className={styles.sectionContent}>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                >
                    <div className={styles.sectionDescriptionContent}>
                        {t("NFC리더 자체 개발-삼성페이/애플페이/후불교통카드 지원, 국내 최고 IP65/68 등급, 산업용 규격 AP 적용")}<br/>
                        {t("KC안전인증, 형식승인, 환경부 ISO15118/OCPP 통신프로토콜 시험 진행 중(’24년 10월 초 완료 예정)")}
                    </div>
                    <div className={styles.section1Dot1ItemContainer}>
                        <div className={styles.section1Dot1Item}>
                            <div className={styles.section1Dot1ItemTitle}>{t("삼성/애플페이 교통카드")}</div>
                            <div className={styles.section1Dot1ItemDescription}>
                                {t("NFC리더 자체 개발")}<br/>
                                {t("삼성페이, 애플페이, 페이온후불교통카드")}
                            </div>
                            <div>
                                <img src={"/images/CSDFS/nfc.png"} alt={"NFC"} height={"60px"}/>
                            </div>
                            <div>
                                <img src={"/images/CSDFS/nfc_board.png"} alt={"NFC 보드"} height={"200px"} style={{ maxWidth: "100%" }}/>
                            </div>
                            <div className={styles.section1ItemDescription}>
                                8bit RISC MCU<br/>
                                NXP PN7160 chipset<br/>
                                ISO 14443A/B, NFC716, Mifare<br/>
                                {t("삼성페이, 애플페이")}
                            </div>
                        </div>
                        <div className={styles.section1Dot1Item}>
                            <div className={styles.section1Dot1ItemTitle}>{t("산업용 규격")}</div>
                            <div className={styles.section1Dot1ItemDescription}>
                                {t("Industrial 등급 AP 탑재")}<br/>
                                &nbsp;
                            </div>
                            <div className={styles.section1Dot1ItemTitle}>ARM Cortex A55 Quad-core</div>
                            <div className={styles.section1Dot1ImageContainer}>
                                <img
                                    src={"/images/CSDFS/arm_cortex_a55_대체이미지.png"}
                                    alt={"보드"}
                                    style={{aspectRatio: "1/1", objectFit: "cover"}}
                                />
                                <img
                                    src={"/images/CSDFS/app_debug.png"}
                                    alt={"앱 디버그"}
                                    style={{aspectRatio: "1/1", objectFit: "cover"}}
                                />
                            </div>
                            <div className={styles.section1Dot1ItemDescription}>
                                <b>
                                    {t("산업용 등급 AP 탑재로 내구성 강화")}<br/>
                                    {t("칩셋 동작온도 -40 ℃ ~ 80 ℃")}
                                </b>
                            </div>
                            <div className={styles.section1Dot1ItemDescription}>
                                {t("규격대비 고용량 부품 적용 설계")}<br/>
                                {t("고장방지 설계")}<br/>
                                {t("비접촉식 CT센서 적용 부식 방지")}<br/>
                                {t("팩토리모드 지원")}<br/>
                            </div>
                        </div>
                        <div className={styles.section1Dot1Item}>
                            <div className={styles.section1Dot1ItemTitle}>{t("국내 최고 IP65등급 이상")}</div>
                            <div className={styles.section1Dot1ItemDescription}>
                                {t("본체 IP65 및 난연 코팅")}<br/>
                                {t("커플러 IP68 등급 획득")}
                            </div>
                            <div className={styles.section1Dot1ImageContainer} style={{marginBottom: "10px"}}>
                                <img src={"/images/CSDFS/charge_stand_head.png"}
                                     alt={"스탠드 충전기"}
                                     style={{aspectRatio: "1/1", objectFit: "cover"}}/>
                                <img src={"/images/CSDFS/charge_connector.png"}
                                     alt={"커넥터"}
                                     style={{aspectRatio: "1/1", objectFit: "cover"}}/>
                            </div>
                            <div className={styles.section1Dot1ImageContainer}>
                                <img src={"/images/CSDFS/test_result_document_1.jpeg"} alt={"시험성적서1"}/>
                                <img src={"/images/CSDFS/test_result_document_2.jpeg"} alt={"시험성적서2"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                >
                    <div className={styles.sectionDescriptionTitle}>{t("2024년 환경부 화재예방형 충전기 기준 발표")}</div>
                    <div className={styles.sectionDescriptionContent}>
                        {t("충전 중 배터리 상태정보(전압, 전류, 온도, SoC 등) 수집하여 CSMS에 전송")}<br/>
                        {t("충전 제어 정보를 수신하여 실시간 충전 제어")}
                    </div>
                    <div className={styles.section1Dot2ItemContainer}>
                        <div
                            className={styles.section1Dot2Item}
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-center"
                        >
                            <div className={styles.description}>
                                <img src={"/images/CSDFS/icon_autoswitching.png"}/>
                                <div>
                                    <div className={styles.title}>{t("오토 스위칭")}</div>
                                    <div>{t("ISO 15118 충전 실패 시 IEC 61851-1 PWM으로 스위칭")}</div>
                                </div>
                            </div>
                            <div className={styles.description}>
                                <img src={"/images/CSDFS/icon_autostop.png"}/>
                                <div>
                                    <div className={styles.title}>{t("오토 스탑")}</div>
                                    <div>
                                        {t("SOC 85% 도달 시 충전 중단 및 재충전 금지")}<br/>
                                        {t("(최대 충전량은 설정 가능)")}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.description}>
                                <img src={"/images/CSDFS/icon_ota.png"}/>
                                <div>
                                    <div className={styles.title}>{t("OTA 업데이트")}</div>
                                    <div>{t("SECC 및 EVSE F/W OTA 업데이트 지원")}</div>
                                </div>
                            </div>
                            <div className={styles.description}>
                                <img src={"/images/CSDFS/icon_obd2.png"}/>
                                <div>
                                    <div className={styles.title}>{t("OBD2 지원")}</div>
                                    <div>
                                        {t("OBD2 기반 BMS 정보 수집 지원")}<br/>
                                        {t("BT/WiFi 데이터 전송 지원")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.section1Dot2Item}
                            data-aos="fade-left"
                            data-aos-anchor-placement="top-center"
                        >
                            <div className={styles.title}>SPEC.</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>CPU</td>
                                        <td>ARM Cortex-A55</td>
                                        <td>Industrial-grade, Quadcore 2.0GHz</td>
                                    </tr>
                                    <tr>
                                        <td>RAM</td>
                                        <td>LPDDR4</td>
                                        <td>LPDDR4 16GB</td>
                                    </tr>
                                    <tr>
                                        <td>PLC</td>
                                        <td>QCA7005</td>
                                        <td>Qualcomm HPGP, 9.8Mbps PHY rate</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>NETWORK</td>
                                        <td>Internal</td>
                                        <td>RJ45, BLE5.0, Wi-Fi combo</td>
                                    </tr>
                                    <tr>
                                        <td>External</td>
                                        <td>LTE(optional)</td>
                                    </tr>
                                    <tr>
                                        <td>NFC</td>
                                        <td>In-house</td>
                                        <td>13.65MHz RFID R/W, Smart Card</td>
                                    </tr>
                                    <tr>
                                        <td>Input</td>
                                        <td>V(AC)</td>
                                        <td>220V, 50/60Hz</td>
                                    </tr>
                                    <tr>
                                        <td>Output</td>
                                        <td>V(AC), Rating</td>
                                        <td>220V, 32A(7kW) | 50A(11kW)</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={4}>SAFETY</td>
                                        <td rowSpan={3}>EV Protection</td>
                                        <td>Over Current, AC Input V Check</td>
                                    </tr>
                                    <tr>
                                        <td>Surge/Leakage Protection</td>
                                    </tr>
                                    <tr>
                                        <td>Short circuit, Over Temp</td>
                                    </tr>
                                    <tr>
                                        <td>IP</td>
                                        <td>Body : IP65 Coupler : IP68</td>
                                    </tr>
                                    <tr>
                                        <td>OS</td>
                                        <td>Android</td>
                                        <td>EVSE + Android 11</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                    data-aos-offset="-100"
                >
                    <div className={styles.sectionDescriptionTitle}>KCAP7PG-CL5G</div>
                    <div className={styles.sectionDescriptionContent}>
                        {t("PLC 모뎀 일체형 AC충전기, Vision AI기반 화재감시/CCTV/블랙박스 기능")}
                    </div>
                    <div className={styles.section1Dot3ItemContainer}>
                        <div
                            className={styles.section1Dot3Item}
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-center"
                        >
                            <div className={styles.imageContainer}>
                                <img src={"/images/CSDFS/charge_stand_head_camera.png"}/>
                                <img src={"/images/CSDFS/charge_stand_heads.png"}/>
                                <img src={"/images/CSDFS/charge_stand_head_front.png"}/>
                            </div>
                            <div className={styles.description}>
                                {t("충전기 탑재 카메라로")}<br/>
                                {t("블랙박스 / 화재감지 / 차량번호 인식 / CCTV")}
                            </div>
                        </div>
                        <div
                            className={styles.section1Dot3Item}
                            data-aos="fade-left"
                            data-aos-anchor-placement="top-center"
                        >
                            <div className={styles.title}>{t("AI 바우처 공급업체")}</div>
                            <div className={styles.description}>{t("K-NBTC 인증(KISA 바이오인식시스템 성능 인증)")}</div>
                            <div className={styles.title}>{t("Vision AI 화재감시")}</div>
                            <div className={styles.description}>{t("객체 탐지-식별 기술 기반의 화재 탐지 기능")}</div>
                            <div className={styles.title}>{t("차량번호 식별")}</div>
                            <div className={styles.description}>{t("영상 기반 차량 식별 및 주차 시스템 연동 지원")}</div>
                            <div className={styles.title}>{t("지능형 CCTV")}</div>
                            <div className={styles.description}>{t("객체 탐지 기반 CCTV 및 VMS 개발 공급")}</div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                    data-aos-offset="-100"
                >
                    <div className={styles.sectionDescriptionTitle}>{t("충전기술 특허 현황")}</div>
                    <div className={styles.section1Dot4ItemContainer}>
                        <div className={styles.section1Dot4Item}>
                            <ul className={styles.list}>
                                <li>{t("고효율 분산 전력 공급 충전시스템, 10-2691919")}</li>
                                <li>{t("차량 정보 인식 기반의 플러그 앤 차지를 수행하기 위한 시스템 및 이를 위한 방법, 10-2686980")}</li>
                                <li>{t("위험 감지 기반 자동 전력차단시스템, 10-2684494")}</li>
                                <li>{t("가변형 충전 전력공급시스템, 10-2024-0087771")}</li>
                                <li>{t("화재 예방 교류충전기, 10-2024-0071919")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default CSFDSSection1;
