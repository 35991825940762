import { useEffect,useState } from 'react';
import { useParams,Link,useHistory } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

function Inquiry({makeHeader}) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [context, setContext] = useState({});
    const Header = makeHeader();
    const history = useHistory();

    const getInquiry = async () => {
        let result = await axios.post(`/etc/main_inquiry_board/list`,{
            main_inquiry_id:parseInt(id)
        })

        if(result.data.main_inquiry_board.length) {
            setContext(result.data.main_inquiry_board[0])
        } else {
            alert("존재하지 않는 게시글입니다.")
            // 강제이동
            history.push("/support/inquiries")
        }
    }

    useEffect(() => {
        getInquiry();
    },[])

    return<div>
        {Header}
        <div className={styles.main_container}>
            <div className={styles.support}>
                <h2>{t("문의 게시판")}</h2>
                <div className={styles.board}>
                    <div>
                        <p style={{fontWeight:600}}>{context.title}</p>
                        <span style={{fontWeight:600}}>{t("작성자")}</span> {context.name}
                        <span style={{marginLeft:"15px",fontWeight:600}}>{t("등록일")}</span> {moment(context.reg_date).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                    <div style={{whiteSpace: "pre-wrap", wordBreak:"break-word"}}>
                        {
                            typeof context.context === 'string' ? context.context : ''
                        }
                    </div>
                </div>
                <div className={styles.reply}>
                    <h3>{t("답변")}</h3>
                    {
                        context.reply === '' ? 
                        <p>{t("아직 답변이 등록되지 않았습니다.")}</p> :
                        <div className={styles.board}>
                            <div>
                                <span style={{fontWeight:600}}>{t("작성자")}</span> {context.reply_user}
                                <span style={{marginLeft:"15px",fontWeight:600}}>{t("등록일")}</span> {moment(context.reply_date).format("YYYY-MM-DD HH:mm:ss")}
                            </div>
                            <div style={{whiteSpace: "pre-wrap"}}>
                                {
                                    typeof context.reply === 'string' ? context.reply : ''
                                }
                            </div>
                        </div>
                    }
                </div>
                <div style={{margin:"30px auto", width:"80px", height:"50px"}}>
                    <Link to="/support/inquiries">
                        <button className={styles.contact_submit} >
                            {t("목록")}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    </div> 
}

export default Inquiry
