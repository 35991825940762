import { useState } from "react";
import styles from "../../css/language.module.css";
import { useTranslation } from "react-i18next";

function LanguageButton(props) {

    const [open, setOpen] = useState(false);
    const { i18n } = useTranslation();

    function onLanguageChange(language) {

        setOpen(false);
        window.localStorage.setItem("lng", language);
        i18n.changeLanguage(language).then();

    }

    return (
        <div className={styles.container}>
            <img
                src={"/images/language.png"}
                alt="Language"
                onClick={() => setOpen(true)}
                title={"Language"}
            />
            <ul className={styles.menu}>
                <li className={styles.item} onClick={() => onLanguageChange("ko")}>한국어</li>
                <li className={styles.item} onClick={() => onLanguageChange("en")}>ENGLISH</li>
            </ul>
            {open && (
                <div className={styles.modalContainer} onClick={() => setOpen(false)}>
                    <div className={styles.modal}>
                        <ul
                            className={styles.languageList}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <li onClick={() => onLanguageChange("ko")}>한국어</li>
                            <li onClick={() => onLanguageChange("en")}>ENGLISH</li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );

}

export default LanguageButton;
