import styles from "../../css/csdfs.module.css";
import { useTranslation } from "react-i18next";

function CSFDSSection2(props) {

    const { t, i18n } = useTranslation();

    return (
        <section className={styles.sectionGray}>
            <h4
                className={styles.sectionTitle}
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
            >
                {t("충전소 화재 예방 시스템")}
            </h4>
            <div className={styles.sectionContent}>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                >
                    <div className={styles.sectionDescriptionTitle}>{t("충전기술 특허 현황")}</div>
                    <div className={styles.section2Dot1ItemContainer}>
                        <div className={styles.section2Dot1Item}>
                            <img src={i18n.language === "ko" ? "images/CSDFS/parkinglot_camera_ko.png" : "images/CSDFS/parkinglot_camera_en.png"} alt={"주차장 카메라"}/>
                        </div>
                        <div className={styles.section2Dot1Item}>
                            <img src={i18n.language === "ko" ? "images/CSDFS/ai_patent_ko.png" : "images/CSDFS/ai_patent_en.png"} alt={"주차장 카메라"}/>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                >
                    <div className={styles.sectionDescriptionTitle}>{t("THERMAL+RGB 듀얼 센서")}</div>
                    <div className={styles.sectionDescriptionContent}>
                        <img src={"images/CSDFS/vms_cameras.png"} alt={"열화상 RGB 카메라"}/>
                    </div>
                    <div className={styles.section2Dot2ItemContainer}>
                        <div
                            className={styles.section2Dot2Item}
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-center"
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>RAII:Edge+VMS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>RGB SENSOR</td>
                                        <td>1920x1080, 68H, V54</td>
                                    </tr>
                                    <tr>
                                        <td>THERMAL SENSOR</td>
                                        <td>320x240, 65mK&lt;100mK, 56H, 42V</td>
                                    </tr>
                                    <tr>
                                        <td>CPU</td>
                                        <td>ARM Cortex-A72 + Cortex-A53</td>
                                    </tr>
                                    <tr>
                                        <td>GPU</td>
                                        <td>ARM MALI-T860MP4</td>
                                    </tr>
                                    <tr>
                                        <td>MEMORY</td>
                                        <td>LPDDR4 4GB</td>
                                    </tr>
                                    <tr>
                                        <td>NPU</td>
                                        <td>HAILO-8(26TOPS)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            className={styles.section2Dot2Item}
                            data-aos="fade-left"
                            data-aos-anchor-placement="top-center"
                        >
                            <ul className={styles.description}>
                                <li>{t("열화상+RGB 카메라 영상을 Edge에서 분석")}</li>
                                <li>{t("딥러닝 학습 모델 기반 영상 탐지 식별 기술 적용")}</li>
                                <li>{t("RGB 영상과 열화상을 분석하여 2중 화재 감지")}</li>
                                <li>{t("Edge 기반 추론-탐지로 네트워크 장애에 대응")}</li>
                                <li>{t("전용 NPU 탑재로 빠르고 정확한 추론 성능 제공")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                >
                    <div className={styles.sectionDescriptionTitle}>{t("화재감지 VMS")}</div>
                    <div className={styles.section2Dot3ItemContainer}>
                        <div className={styles.section2Dot3Item}>
                            <div className={styles.imageContainer}>
                                <img src={"images/CSDFS/vms_web_1.png"} alt={"VMS 웹"}/>
                                <img src={"images/CSDFS/vms_web_2.png"} alt={"VMS 웹"}/>
                                <img src={"images/CSDFS/vms_web_3.png"} alt={"VMS 웹"}/>
                            </div>
                        </div>
                        <div className={styles.section2Dot3Item}>
                            <ul className={styles.description}>
                                <li>{t("Edge에서 감지한 이벤트 영상 확인")}</li>
                                <li>{t("화재 발생 시 관리자 알림/푸시 서비스")}</li>
                                <li>{t("AI Edge 상태 모니터링")}</li>
                                <li>{t("픽셀 별 온도 측정/알람 온도 설정 기능 지원")}</li>
                                <li>{t("충전소 내 요주의 장비/지역 발열 모니터링")}</li>
                            </ul>
                        </div>
                        <div
                            className={styles.section2Dot3Item}
                            data-aos="fade-up"
                            data-aos-anchor-placement="top-center"
                        >
                            <table>
                                <colgroup>
                                    <col/>
                                    <col/>
                                    <col/>
                                    <col/>
                                    <col/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th colSpan={2}>SaaS</th>
                                        <th colSpan={2}>On-Premise</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{t("환경")}</td>
                                        <td colSpan={2}>{t("가상환경")}</td>
                                        <td colSpan={2}>
                                            {t("물리적 환경")}<br/>
                                            {t("(하드웨어, 네트워크, 백업 등을 직접 혹은 IDC 일부 위탁)")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>{t("비용")}</td>
                                        <td>{t("초기투자")}</td>
                                        <td>{t("없음")}</td>
                                        <td>{t("초기투자")}</td>
                                        <td>{t("높음")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("유지비용")}</td>
                                        <td>○</td>
                                        <td>{t("유지비용")}</td>
                                        <td>○</td>
                                    </tr>
                                    <tr>
                                        <td>{t("보안")}</td>
                                        <td colSpan={2}>
                                            {t("일정 수준이상의 보안, 규정 준수")}<br/>
                                            {t("데이터 보호 및 보안 부담 경감")}
                                        </td>
                                        <td colSpan={2}>
                                            {t("완벽한 시스템 제어권")}<br/>
                                            {t("보안 관리 투명성 보장")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("운영")}</td>
                                        <td colSpan={2}>{t("CSP에 위임")}</td>
                                        <td colSpan={2}>{t("직접 운영 또는 IDC 일부 위탁")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("확장성")}</td>
                                        <td colSpan={2}>○</td>
                                        <td colSpan={2}>X</td>
                                    </tr>
                                    <tr>
                                        <td>{t("맞춤형 시스템")}</td>
                                        <td colSpan={2}>X</td>
                                        <td colSpan={2}>○</td>
                                    </tr>
                                    <tr>
                                        <td>{t("정기적 인프라 투자")}</td>
                                        <td colSpan={2}>X</td>
                                        <td colSpan={2}>○</td>
                                    </tr>
                                    <tr>
                                        <td>{t("사용량기반 요금")}</td>
                                        <td colSpan={2}>○</td>
                                        <td colSpan={2}>X</td>
                                    </tr>
                                    <tr>
                                        <td>{t("데이터백업 자동화")}</td>
                                        <td colSpan={2}>○</td>
                                        <td colSpan={2}>X</td>
                                    </tr>
                                    <tr>
                                        <td>{t("무중단 서비스")}</td>
                                        <td colSpan={2}>○</td>
                                        <td colSpan={2}>X</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default CSFDSSection2;
