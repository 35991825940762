import styles from "../../css/csdfs.module.css";
import { useTranslation } from "react-i18next";

function CSFDsSection3(props) {

    const { t } = useTranslation();

    return (
        <section className={styles.section}>
            <h4
                className={styles.sectionTitle}
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
            >
                {t("지상 주차 솔루션")}
            </h4>
            <div className={styles.sectionContent}>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                >
                    <div className={styles.section3Dot1ItemContainer}>
                        <div className={styles.section3Dot1Item}>
                            <div className={styles.title}>{t("상가 / 아파트")}</div>
                            <img src={"/images/CSDFS/solution_commercial_residential.png"} alt={"상가/아파트 충전소 배치"}/>
                            <div className={styles.description}>
                                <div className={styles.title}>{t("상가/아파트 지상주차솔루션 제안")}</div>
                                <ul>
                                    <li>{t("태양광 카포트 + AC 충전기 구성")}</li>
                                    <li>{t("태양광 패널-인버터-스마트 스위치-AC 충전기")}</li>
                                    <li>{t("전력 계통 구성이 쉽고, 충전 수요가 많은 장소")}</li>
                                    <li>{t("스마트 스위치로 태양광과 한전 간 인입 전력 스위칭")}</li>
                                    <li>{t("BSS 용량 감축 또는 미설치 가능")}</li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.section3Dot1Item}>
                            <div className={styles.title}>{t("졸음쉼터 / 도서·산간지역")}</div>
                            <img src={"/images/CSDFS/solution_remote.png"} alt={"상가/아파트 충전소 배치"}/>
                            <div className={styles.description}>
                                <div className={styles.title}>{t("졸음쉼터/도서·산간 지상주차솔루션 제안")}</div>
                                <ul>
                                    <li>{t("태양광 카포트 + DC-DC 충전기 구성")}</li>
                                    <li>{t("태양광 패널-BSS-DC2DC 충전기")}</li>
                                    <li>{t("전력 계통 구성이 어렵고, 수요가 적은 장소")}</li>
                                    <li>{t("친환경 에너지 간헐성으로 BSS 추가 필요")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.sectionDescription}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                    style={{ marginBottom: "200px" }}
                >
                    <div className={styles.sectionDescriptionTitle}>{t("솔라 카포트 충전 시스템 구축 사례")}</div>
                    <div className={styles.section3Dot2ItemContainer}>
                        <div className={styles.section3Dot2Item}>
                            <div className={styles.imageContainer}>
                                <div>
                                    <div>
                                        <img src={"/images/CSDFS/ground_station_1.jpeg"} alt={"지상 충전소 1"}/>
                                        <p>{t("AVIVA, 스코틀랜드, 솔라카포트 주차장")}</p>
                                    </div>
                                    <div>
                                        <img src={"/images/CSDFS/ground_station_2.png"} alt={"지상 충전소 2"}/>
                                        <p>{t("덴마크, 고속도로 솔라카포트 충전소")}</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img src={"/images/CSDFS/ground_station_3.jpg"} alt={"지상 충전소 3"}/>
                                        <p>{t("솔라카포트 충전소")}</p>
                                    </div>
                                    <div>
                                        <img src={"/images/CSDFS/ground_station_4.jpg"} alt={"지상 충전소 4"}/>
                                        <p>{t("포르쉐, 솔라카포트 충전소")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default CSFDsSection3;
