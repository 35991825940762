import React, { useState, useEffect } from 'react';
import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

const Footer = ({scrollRef}) => {
    const { t } = useTranslation();
    const [roll, setRoll] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);
    const handleScroll = () => {
        const scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= 200) setRoll(true);
        else setRoll(false);
    }
    const goHeader = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    return (
        <footer id="footer" className={styles.footer}>
            <div className={styles.f_1}>
                <img alt="logo" src="/images/koolsign.png" />
                <div><span>쿨사인(주)</span> 서울시 구로구 디지털로 27가길 27 아남빌딩 7층</div>
                <div>
                    <a href="tel:1600-4045" className={styles.tel}>TEL&#41; 1600-4045 | </a>
                    {/* <a href="tel:1600-4045" className={styles.tel}>대표번호&#41; 1600-4045 | </a> */}
                    <a href="tel:02-6295-8044" className={styles.tel}>FAX&#41; 02-6295-8044</a>
                </div>
            </div>

            <div className={styles.f_2}>
                <div>{t("기업정보")}<img alt="arrow-r-b" src="/images/arrow-r-b.png" /></div>
                <div>© 2024 <span>KOOLSIGN all rights reserved.</span></div>
            </div>

            <div className={`${styles.top_btn} ${roll === true ? styles.roll : ''}`} onClick={goHeader} ><img alt="top" src="/images/top.png" /></div>
        </footer>
    );
};

export default Footer;
