import styles from '../../css/style.module.css';
import { useTranslation } from "react-i18next";

function CSSS() {

    const { t } = useTranslation();

    return (
        <section className={styles.main_container}>
            <h3 className={styles.h3} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                {t("충전소 통합정보 시스템 (CSIS)")}
            </h3>

            <div className={`${styles.csms} ${styles.csms_4} ${styles.add_platform_7}`}>
                <div className={`${styles.csms_txt} ${styles.csms_txt_4} ${styles.add_platform_7_txt}`}>
                    {/*<div className={styles.csms_title} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">*/}
                    {/*    충전 정보 + 디지털 사이니지*/}
                    {/*</div>*/}

                    <div className={styles.csms_4_wrap}>
                        <div className={styles.csms_4_1}>
                            <div className={styles.add_platform_7_1_img} data-aos="fade-right"></div>
                            <div className={styles.csms_4_1_list} data-aos="fade-left">
                                <ul>
                                    <li className={styles.bordertitle}>
                                        <span style={{whiteSpace: "nowrap"}}>
                                            {t("종합 충전 정보 디스플레이 관리")}
                                        </span>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전소 디스플레이 (LCD, 전광판)")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전소 안내, 충전기 상태 안내 디스플레이")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기 디스플레이")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기 상태, 충전 정보, 예약 정보 표출관리")}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className={styles.csms_4_2}>
                            <div className={styles.csms_4_2_list} data-aos="fade-right">
                                <ul>
                                    <li className={styles.bordertitle}>
                                        <span>
                                            {t("디지털 콘텐츠 관리 시스템")}
                                        </span>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("동영상/이미지/HTML 등 다양한 콘텐츠 지원")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("콘텐츠 관리 및 광고 스케줄 관리 기능")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기 유휴 시간 광고 편성 기능")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기-디지털사이니지 통합 관리 지원")}
                                    </li>
                                </ul>
                            </div>

                            <div className={styles.add_platform_7_2_img} data-aos="fade-left"></div>
                        </div>
                    </div>
                </div>
            </div>





            <div className={`${styles.csms} ${styles.csms_4} ${styles.add_platform_8}`}>
                <div className={`${styles.csms_txt} ${styles.csms_txt_4} ${styles.add_platform_8_txt}`}>
                    <div className={styles.csms_title} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-offset="-550">
                        {t("충전 정보 + 디지털 사이니지")}
                    </div>

                    <div className={styles.csms_4_wrap}>
                        <div className={styles.csms_4_2}>
                            <div className={styles.csms_4_2_list} data-aos="fade-left">
                                <ul>
                                    <li className={styles.bordertitle}>
                                        <span>
                                            {t("종합 충전 정보 디스플레이 관리")}
                                        </span>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("CSMS 연동/통합 형 관리 시스템")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전소 통합 정보 표출")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전기별 충전/예약 상태, 잔여 충전시간 표출")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("예약 기기 상태 및 예약자 호출 서비스")}
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.add_platform_8_1_img} data-aos="fade-right"></div>
                        </div>

                        <div className={styles.csms_4_1}>
                            <div className={styles.add_platform_8_2_img} data-aos="fade-left"></div>
                            <div className={styles.csms_4_1_list} data-aos="fade-right">
                                <ul>
                                    <li className={styles.bordertitle}>
                                        <span>
                                            {t("충전소 통합 정보 관리")}
                                        </span>
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("충전 가이드, 제휴 충전소 안내 서비스")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("공익/공공 캠페인 영상 표출")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("간편한 광고 스케쥴 제작/배포 기능")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("단말/콘텐츠 통계")}
                                    </li>

                                    <li>
                                        <span className={styles.txt_span}>▪</span> {t("단말 상태 실시간 관제 및 원격 업데이트 서비스")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CSSS
