import { useEffect,useState } from 'react';
import styles from '../../css/style.module.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { useTranslation } from "react-i18next";
function Inquiries({makeHeader}) {
    const [inquiries, setInquiries] = useState([]);
    const [totalIquiries, setTotalIquiries] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const Header = makeHeader();
    const { t } = useTranslation();

    const getInquiries = async () => {
        let result = await axios.get(`/etc/main_inquiry_board/list?element_count=7&page_no=${page}`)
        setInquiries(result.data.main_inquiry_board);
        setTotalPages(result.data.pagination.total_page);
        setPage(result.data.pagination.page_no);
        setTotalIquiries(result.data.pagination.total_element);
    }

    const handlePageClick = async (event) => {
        setPage(event.selected + 1);
    }

    useEffect(() => {
        getInquiries();
    },[page])

    return<div>
        {Header}
        <div className={styles.main_container}>
            <div className={styles.support}>
                <h2>{t("문의 게시판")}</h2>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom:"5px"}}>
                    <p><span>{totalIquiries}</span> {t("항목")}</p>
                    <Link to="/support/inquiries/create">
                        <button className={styles.create_post_button} >
                            {t("글 쓰기")}
                        </button>
                    </Link>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{width:"10%"}}>NO.</th>
                            <th style={{width:"10%"}}>{t("분류")}</th>
                            <th style={{width:"6%"}}></th>
                            <th style={{width:"31%"}}>{t("제목")}</th>
                            <th style={{width:"13%"}}>{t("작성자")}</th>
                            <th style={{width:"30%"}}>{t("등록일")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inquiries.map((inquiry) => {
                                return <tr key={inquiry.main_inquiry_id}>
                                    <td>{inquiry.main_inquiry_id}</td>
                                    <td>{inquiry.type}</td>
                                    <td style={{fontSize:"12px", color:"#F16722"}}>{inquiry.reply === '' ? "" : t("답변 완료")}</td>
                                    <td style={{wordBreak: "keep-all"}}><Link to={'/support/inquiry/'+inquiry.main_inquiry_id}>{inquiry.title}</Link></td>
                                    <td>{inquiry.name}</td>
                                    <td>{moment(inquiry.reg_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <ReactPaginate 
                    className={styles.pagination}
                    onPageChange={handlePageClick}
                    breakLabel="..."
                    nextLabel=">"
                    previousLabel="<"
                    pageRangeDisplayed={5}
                    pageCount={totalPages} 
                />
            </div>
        </div>
    </div> 
}

export default Inquiries
